.video-box {
  display: flex;
  align-items: center;
  .image {
    width: 128px;
    height: 72px;
    background-position: center;
    background-size: cover;
  }
  .text {
    padding: 5px 20px;
    h3 {
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      color: rgba(24, 28, 50, 0.9);
    }
    p {
      font-size: 13px;
      line-height: 20px;
      color: rgba(89, 93, 110, 0.9);
      font-weight: 300;
    }
  }
}
