.nav-category {
  height: 40px;
  padding: 12px 28px;
  cursor: default;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
  height: 40px;
  transition: 0.3s;
  &__trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px);
    &.button{
      text-align: left;
      .nav-item__title{
        margin-left: 0;
      }
    }
  }
  &__button {
    transition: 0.3s;
  }
  path[fill="#494b74"] {
    transition: 0.3s;
    fill: #494b74;
  }
  .svg-rect {
    transition: 0.3s;
    fill: #494b74;
  }
  &.active,
  &:hover {
    background-color: #1a1a27;
    path[fill="#494b74"] {
      fill: #5d78ff !important;
    }
    .svg-rect {
      fill: #5d78ff !important;
    }
    .nav-item__button {
      opacity: 0.7;
    }
  }
  &__title {
    flex-grow: 1;
    margin-left: 12px;
    transition: 0.3s;
    height: 100%;
    padding: 10px 0;
    line-height: 20px;
    color: rgb(162, 165, 185);
    &:hover {
      color: lighten($nav-item, 20%);
    }
  }
  &__button {
    width: 16px;
    height: 16px;
    opacity: 0.2;
    border-radius: 100%;
    @include button-reset;
    border: 1px solid #5d78ff;
    position: relative;
    &:before {
      @include plus-rectangle;
      transform: translate(-50%, -50%);
    }
    &:after {
      @include plus-rectangle;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

.sidebar {
  height: 100vh;
  position: fixed;
  width: 265px;
  transition: 0.2s;
  left: 0;
  top: 0;
  background-color: #1e1e2d;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  &__header {
    display: flex;
    background-color: #1a1a27;
    height: 60px;
    align-items: center;
    padding: 20px 28px;
    transition: 0.3s;
    path[fill] {
      transition: 0.3s;
    }
    &:hover {
      .arrow {
        fill: #5d78ff;
      }
    }
  }
  &__logo {
    flex-grow: 1;
    &__background {
      fill: $app-light;
    }
  }
  &.collapsed {
    width: 70px;
    .sidebar {
      &__header {
        padding: 20px;
        justify-content: center;
      }
      &__logo {
        display: none;
      }
      &__button {
        transition: 0.3s;
        transform: scaleX(-1);
      }
    }
    .nav {
      &-category {
        font-size: 0;
      }
      &-item {
        padding: 0 10px;
        justify-content: center;
        &__button,
        &__title {
          display: none;
        }
      }
    }
    & + .main-container {
      margin-left: 70px;
      width: calc(100% - 70px);
      .main-navbar {
        width: calc(100% - 70px);
      }
      .actionbar {
        left: 70px;
      }
      .breadcrumbs__container {
        left: 70px;
      }
    }
  }
}
