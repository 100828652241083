.image-uploader {
  display: flex;
  &__label {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 2px;
    color: #646c9a;
  }
  &__box {
    width: 130px;
    height: 130px;
    padding: 10px;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 10px;
    transition: 0.2s;
    overflow: hidden;
    img {
      max-width: 100%;
    }
    &.new {
      cursor: pointer;
      .inner {
        text-align: center;
        color: #a2a5b9;
      }
      &:hover {
        background: rgba($color: #e2e5ec, $alpha: 0.5);
      }
    }
    &__overlay {
      position: absolute;
      opacity: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-radius: 4px;
      transition: 0.2s;
      background: rgba($color: #5d78ff, $alpha: 0.8);
      box-shadow: 0px 0px 13px #523f690d;
      .btn {
        margin: 5px;
        min-width: 80px;
        padding: 2px 10px;
        min-height: 28px;
        border-radius: 5px;
        span {
          padding-left: 5px;
          font-size: 13px;
          line-height: 16px;
        }
        &-edit {
          background-color: #44b2f1;
        }
        &-delete {
          background-color: #fd397a;
          svg {
            max-width: 20px;
          }
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
