.main-container {
  margin-left: 265px;
  width: calc(100% - 265px);
  transition: 0.2s;
}

.main-navbar {
  width: calc(100% - 265px);
  transition: 0.2s;
  position: fixed;
  z-index: 800;
  top: 0;
  background: white;
  box-shadow: 0px 0px 13px #523F690C;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
  }
}

.nav-button {
  display: flex;
  align-items: center;
  @include button-reset;
  transition: 0.3s;
  &:hover {
    .nav-button__logo::after {
      opacity: 0.2;
    }
  }
  &__logo {
    position: relative;
    &--right {
      order: 2;
    }
    &:after {
      content: "";
      position: absolute;
      transition: 0.3s;
      top: 0;
      left: 0;
      border-radius: 5px;
      width: 24px;
      height: 24px;
      background-color: #fff;
      opacity: 0.1;
    }
  }
}
