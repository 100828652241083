.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000000b4;
  z-index: 1000;
  display: flex;
}
.popup {
  max-width: 424px;
  margin: auto;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  opacity: 0;
  transition: 0.2s;
  &.active {
    opacity: 1;
  }
  &__header {
    padding: 18px 30px;
    display: flex;
    align-items: center;
    position: relative;
    button:only-child {
      margin-left: auto;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $separator;
    }
  }
    &__body__images {
    overflow-y: scroll;
    max-height: calc(90vh - 120px);
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__footer {
    text-align: end;
    button {
      margin-left: 11px;
    }
    padding: 10px 15px 10px 15px;
    border-top: 1px solid #ebedf2;
    img {
      vertical-align: middle;
    }
  }
  &.popup__image {
    max-width: 650px;
    .popup__body {
      padding: 0 30px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }
    .popup__header {
      padding: 18px 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &:after {
        display: none;
      }
    }
  }
  &.popup__login {
    .popup__body {
      padding: 20px;
      .login_button {
        text-align: center;
      }
    }
  }
}
