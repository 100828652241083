.breadcrumbs {
  &__container {
    padding: 5px 8px;
    background-color: #fff;
    box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
    position: fixed;
    z-index: 10;
    top: 80px;
    left: 265px;
    right: 0;
    transition:0.2s;
  }
  &__list {
    display: flex;
    align-items: center;
  }
  &__item {
    display: inline-flex;
    align-items: center;
    &:not(:last-child) {
      &::after {
        content: '';
        display: inline-block;
        background: url('../images/icons/breadcrumbs-arrow.svg');
        background-size: 8px 15px;
        background-repeat: no-repeat;
        width: 8px;
        height: 15px;
        margin-bottom: 2px;
      }
    }
    button,
    span {
      display: inline-block;
      padding: 0 10px;
      font-size: 11px;
      line-height: 20px;
      color: rgba(116, 120, 141, 1);
    }
    button {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
