.actionbar {
  background: #fff;
  box-shadow: 0px 0px 13px #523f690c;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 265px;
  transition: 0.2s;
  &__container {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    &.align {
      &-space {
        justify-content: space-between;
      }
      &-right {
        justify-content: flex-end;
      }
    }
  }
  &__group {
    display: flex;
    align-items: center;
    .btn {
      margin-left: 20px;
      &:only-child {
        margin-left: 0;
      }
    }
  }
}
