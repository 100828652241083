.dashboard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 13px #523f690c;
  border-radius: 5px;
  margin: 10px 0;
  margin-bottom: 8px;
  &__rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__rate {
      display: flex;
      align-items: center;
      .value {
        font-size: 25px;
        line-height: 25px;
        color: #181c32;
        font-weight: 600;
        margin-right: 16px;
      }
      .rating-wrapper {
        span {
          margin: 0 2px;
        }
      }
    }
    &__comments {
      span {
        font-size: 13px;
        line-height: 20px;
        color: #7e8299;
      }
    }
  }
  &__tabs {
    display: flex;
    background-color: #f2f3f8;
    .tab {
      flex: 1;
      background-color: #ebecf3;
      padding: 9px 0;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:last-child {
        &::before {
          display: none;
        }
      }
      &:hover {
        background-color: #e5e6ef;
      }
      &.active {
        background-color: white;
        position: relative;
        &:hover {
          background-color: white;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #5d78ff;
          height: 2px;
        }
        &:before {
          display: none;
        }
      }
      a {
        display: inline-block;
        width: 100%;
      }
    }
  }
  &--small {
    flex-basis: 49.2%;
  }
  &__button-section {
    padding: 20px 30px;
  }
  &__list {
    &-item {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      position: relative;
      .country {
        margin-right: auto;
        &-label--hidden {
          visibility: hidden;
        }
      }
      .no-margin {
        margin: 0;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $separator;
      }
    }
  }
  &__form {
    padding: 30px;
    &__submit {
      padding: 20px 30px;
    }
    &__footer {
      border-top: 1px solid $separator;
      display: flex;
      justify-content: flex-end;
      padding: 10px 30px;
      &.md {
        justify-content: flex-start;
        .box {
          width: 100%;
          max-width: 600px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    &--pr-10 {
      //padding-left: 10px;
      padding-right: 10px;
    }
  }
  &__channel-label {
    padding: 30px;
    padding-top: 0;
    .channel-label-text {
      margin-bottom: 11px;
    }
  }
  &__header {
    display: flex;
    padding: 20px 30px 20px;
    align-items: center;
    border-bottom: 1px solid $separator;
    &--edit {
      padding: 18px 30px;
    }
  }
  &__title {
    margin-right: auto;
    margin-left: 10px;

    &.editor {
      margin-right: 5px;
    }
  }
  &__searchfield {
    padding: 18px 30px;
    display: flex;
    .search-box {
      flex: 1;
      padding: 10px;
      border: 1px solid #e2e5ec;
      border-radius: 4px;
    }
  }
}
.main-dashboard {
  min-height: 100vh;
  padding-top: 80px;
  background-color: $dashboard-background;
  position: relative;
  &__container {
    padding: 40px 30px 60px;
  }
}

.selectbox {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #f0f3ff 0% 0% no-repeat padding-box;
  padding: 4px 10px;
  padding-left: 4px;
  text-align: left;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #93a2dd;
}
.icon__container {
  path {
    fill: $svg-icon;
  }
  circle {
    fill: $svg-icon;
  }
  .svg-rect {
    fill: $svg-icon;
  }
}

.dashboard-wrapper-flex {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.column-1 {
  flex-basis: calc(33.3333% - 10px);
  &--container {
    display: flex;
    flex-direction: column;
  }
}

.column-2 {
  flex-basis: calc(66.666% - 10px);
}

.column-3 {
  flex-basis: 100%;
}

.column-half {
  flex-basis: calc(50% - 10px);
}
