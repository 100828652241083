.text-editor {
    padding: 20px 40px;
    margin: 0 0 20px;
  &-label {
    font-size: 13px;
    line-height: 20px;
    color: #646c9a;
    margin-bottom: 2px;
  }
  .rc-md-editor {
    border-radius: 4px;
    overflow: hidden;
  }
  &.md{
    padding: 10px;
  }
}
