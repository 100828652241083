.video-boxes {
  display: flex;
  flex-flow: wrap;
  padding: 15px 0px;
  .video {
    margin: 10px;
    width: 155px;
    &__image {
      width: 100%;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .btn{
        border: none;
      }
    }
    &__title {
      padding: 5px 0;
      .title {
        font-size: 10px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: #595d6e;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
