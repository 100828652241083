$nav-item: #a2a5b9;
$nav-category: #4c4e6f;
$app-light: #3699ff;
$app-dark: #5d78ff;
$tab-text-active: #48465b;
$dashboard-background: #f2f3f8;
$tab-active: rgba(255, 255, 255, 1);
$blue: #567cfb;
$table-head: #595d6e;
$separator: #ebedf2;
$label-green: #0abbbb;
$svg-icon: rgb(93, 120, 255);
$pink: #fd397a;
$label-form: #646c9a;
$input-form-border: #e2e5ec;
$green: #0abe8c;
