.inp-wrapper {
  width: 100%;
  .inp-group {
    input {
      width: 100%;
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid $input-form-border;
      width: 100%;
      outline: none;
      transition: 0.2s;
      &:focus,
      &.visited {
        border-color: #9aabff;
      }
      &[type='hidden'] {
        display: none;
      }
    }
    display: flex;
    .form-span {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      font-size: 13px;
      line-height: 15px;
      color: #495057;
      background-color: #f7f8fa;
      border: 1px solid $input-form-border;
      padding: 10px;
    }
    .postfix {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: -3px;
      &-color {
        width: 16px;
        height: 16px;
        background-color: #44b2f1;
        border: 1px solid $input-form-border;
      }
    }
    .prefix {
      margin-right: -3px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
